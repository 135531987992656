/* body {
  background: rgba(47,201,226,0.1) ;
  margin: 0;
  padding: 0;
  font-family: "Montserrat",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* body::after{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    background-image: url("../src/assets/ss.png");
    background-size: cover;
    opacity: 0.5;
    pointer-events: none;
    width: 100%;
    height: 100%;
} */
select,
option {
    -webkit-appearance: none;
}
input,
select,
option,
button {
    background-color: #ffffff;
    border-radius: 1vh;
    border-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}
body ul {
    padding: 0;
}
body.dark-mode {
    /* background: #191326!important; */
    /* background: radial-gradient(
        circle,
        rgba(17, 17, 71, 1) 0%,
        rgba(11, 12, 69, 1) 22%,
        rgba(11, 36, 55, 1) 100%
    ) !important; */
}
body.dark-mode .link-mode.active {
    color: #fff;
}
body.dark-mode .sub-trade .lhSWqe.active {
    color: rgb(0, 0, 0);
}

body.dark-mode .bt-menu svg {
    /* fill: #fff; */
}
body.dark-mode .drep-mode {
    color: #fff;
}
body.fix .menu-fixed {
    /* background: #fff; */
    box-shadow: 1px 2px 6px 1px #bbaeae;
}
body.dark-mode.fix .menu-fixed {
    box-shadow: 1px 2px 6px 1px #292828;
    /* background: radial-gradient(
        circle,
        rgba(17, 17, 71, 1) 0%,
        rgba(11, 12, 69, 1) 22%,
        rgba(11, 36, 55, 1) 100%
    ); */
}
body img {
    height: auto;
    max-width: 100%;
}
.main {
    margin-top: 72px;
}
@media screen and (max-width: 1025px) {
    body h3,
    body h2 {
        font-size: 30px !important;
    }
    body p {
        font-size: 16px !important;
    }
}
@media screen and (max-width: 476px) {
    body h1 {
        font-size: 20px !important;
    }
    body h3,
    body h2 {
        font-size: 22px !important;
    }
    body p {
        font-size: 14px !important;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
html {
    scroll-behavior: smooth;
}
